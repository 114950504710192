<template>
  <tr>
    <!-- TODO: Validate Zone Record Item -->
    <td>
      <v-select v-model="record.type" dense :items="dnsRecordTypes" />
    </td>
    <td>
      <v-text-field v-model="record.name" single-line dense placeholder="Name" />
    </td>
    <td>
      <v-text-field v-model="record.content" single-line dense placeholder="Wert" />
    </td>
    <td>
      <v-text-field v-model="record.ttl" single-line dense placeholder="TTL" />
    </td>
    <td>
      <v-text-field v-model="record.prio" single-line dense placeholder="Priorität" />
    </td>
    <td>
      <v-btn x-small color="error" :disabled="pendingDeleteRecord || pendingRefresh" @click="confirmVisible = true">
        Record Löschen
      </v-btn>

      <AppDialog :is-open="confirmVisible" max-width="500" @onCloseDialog="confirmVisible = false">
        <template slot="title"> Record löschen? </template>

        <p>
          Bist du sicher, dass du den Record <b>{{ record.name }}</b> löschen möchtest? Diese Aktion kann nicht
          rückgängig gemacht werden.
        </p>

        <v-btn color="default" class="mr-2 mt-2" @click="confirmVisible = false"> Abbrechen </v-btn>
        <v-btn color="error" class="mt-2" @click="onRemoveRecord">Record löschen</v-btn>
      </AppDialog>
    </td>
  </tr>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'

import { dnsRecordTypes } from '@/config'

import AppDialog from '@/components/UI/AppDialog'

export default defineComponent({
  name: 'RecordItem',
  components: {
    AppDialog,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root: { $store } }) {
    const confirmVisible = ref(false)

    // store
    const pendingDeleteRecord = computed(_ => $store.state.zone.pendingDeleteRecord)
    const pendingRefresh = computed(_ => $store.state.zone.pendingRefresh)
    const deleteRecord = recordId => $store.dispatch('zone/deleteRecord', recordId)
    const removeRecord = recordId => $store.dispatch('zone/removeRecord', recordId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const onRemoveRecord = _ => {
      if (pendingDeleteRecord.value) {
        return
      }

      if (props.record.id) {
        deleteRecord(props.record.id)
          .then(data => {
            if (data.errors) {
              addToast({
                msg: 'Record erfolgreich gelöscht',
                type: 'success',
              })
              confirmVisible.value = false
            } else {
              addToast({
                msg: 'Löschung fehlgeschlagen',
                type: 'error',
              })
            }
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
          })
      } else {
        removeRecord(props.record).then(_ => {
          addToast({
            msg: 'Record erfolgreich gelöscht',
            type: 'success',
          })
          confirmVisible.value = false
        })
      }
    }

    return {
      confirmVisible,
      dnsRecordTypes,
      pendingRefresh,
      pendingDeleteRecord,
      onRemoveRecord,
    }
  },
})
</script>
